import { lazy, } from 'react';
import OngoingEvaluations from '../views/OngoingEvaluationView';

const DefaultView = lazy(() => import('../views/DefaultView'));
const NotFoundView = lazy(() => import('../views/NotFoundView'));
const DashboardView = lazy(() => import('../views/DashboardView'));
const DummyView = lazy(() => import('../views/DummyView'));
const CompanyView = lazy(() => import('../views/CompanyView'));
const SupervisoryView = lazy(() => import('../views/Supervisory/SupervisoryView'));
const DepartmentView = lazy(() => import('../views/DepartmentView'));
const PositionView = lazy(() => import('../views/PositionView'));
const RoleView = lazy(() => import('../views/RoleView'));
const UserView = lazy(() => import('../views/UserView'));
const PanTypeView = lazy(() => import('../views/PanTypeView'));
const ActivityLogsReportView = lazy(() => import('../views/ActivityLogsReportView'));
const LocationView = lazy(() => import('../views/LocationView'));
const OrganizationRoleView = lazy(() => import('../views/OrganizationRoleView'));
const RecruitmentReportView = lazy(() => import('../views/RecruitmentReportView'));
const JobTemplateView = lazy(() => import('../views/JobTemplateView'));
const InboxView = lazy (() => import('../views/InboxView'))
const DataMigrationView = lazy(() => import('../views/DataMigrationView'));
const RecruitmentDashboardView = lazy(() => import('../views/RecruitmentDashboardView'));
const CourseView = lazy(() => import('../views/CourseView'));
const CourseLearnersView = lazy(() => import('../views/CourseLeanersView'));
const LMSReportView = lazy(() => import('../views/LMSReportView')); 
const ScoringTemplateView = lazy(() => import('../views/ScoringTemplateView'))
const EvaluationTemplateView = lazy(() => import('../views/EvaluationTemplateView'))
const PmsWorkflowView = lazy(() => import('../views/PmsWorkflowView'))
const EvaluationRequestView = lazy(() => import('../views/EvaluationRequestView'))



// Home landing page
const OrganizationHomeView = lazy(() => import('../views/OrganizationHomeView'));
const WorkflowHomeView = lazy(() => import('../views/WorkflowHomeView'));
const RecruitmentHomeView = lazy(() => import('../views/RecruitmentHomeView'));
const HRHomeView = lazy(() => import('../views/HRHomeView'));
const ReportsHomeView = lazy(() => import('../views/ReportsHomeView'));
const UserManagementHomeView = lazy(() => import('../views/UserManagementHomeView'));
const LearningManagementHomeView = lazy(() => import('../views/LearningManagementHomeView'));
const PerformanceManagementHomeView = lazy(() => import('../views/PerformanceManagementHomeView'));


// Recruitment settings
const ManPowerRequestView = lazy(() => import('../views/ManPowerRequestView'));
const PanRequestView = lazy(() => import('../views/PANRequestView'));
const JobPostingView = lazy(() => import('../views/JobPostingView'));
const ManageApplicationForm = lazy(() => import('../forms/ManageApplication/ManageApplicationForm'));
const ApplicantView = lazy(() => import('../views/ApplicantView'));
const JobApplicationSettingView = lazy(() => import('../views/JobApplicationSettingView'));
const RecruitmentPagesView = lazy(() => import('../views/RecruitmentPagesView'));
const RecruitmentStagesView = lazy(() => import('../views/RecruitmentStageView'));

// Workflow
const RequestToHireWorkFlowView = lazy(() => import('../views/RequestToHireWorkFlowView'));
const PersonnelActionNoticeView = lazy(() => import('../views/PersonnelActionNoticeWorkflowView'));

// HR
const EmployeeView = lazy(() => import('../views/EmployeeView'));
const EmployeeForm = lazy(() => import('../forms/Employee'));

const getNonNavItems = () => ([
  {
    id: "default",
    path: "/app/default",
    component: DefaultView,
  },
  {
    id: "404",
    path: "/app/404",
    component: NotFoundView,
  },
  {
    id: "manage_application",
    path: [
      "/app/recruitment/manage_applications/:job_posting_id/:stage_id",
      "/app/recruitment/manage_applications/:job_posting_id/:stage_id/filter/:params?",
    ],
    component: ManageApplicationForm,
  },
  {
    id: "employee_form",
    path: [
      "/app/hr/employees/new",
      "/app/hr/employees/:id",
      "/app/hr/employees/edit/:id",
    ],
    component: EmployeeForm,
  },
  {
    id: "organization_home",
    path: "/app/organization/home",
    component: OrganizationHomeView,
  },
  {
    id: "workflow_home",
    path: "/app/workflows/home",
    component: WorkflowHomeView,
  },
  {
    id: "recruitment_home",
    path: "/app/recruitment/home",
    component: RecruitmentHomeView,
  },
  {
    id: "hr_home",
    path: "/app/hr/home",
    component: HRHomeView,
  },
  {
    id: "reports_home",
    path: "/app/reports/home",
    component: ReportsHomeView,
  },
  {
    id: "user_management_home",
    path: "/app/user_management/home",
    component: UserManagementHomeView,
  },
  {
    id: "learning_management_home",
    path: "/app/learning_management/home",
    component: LearningManagementHomeView,
  },
  {
    id: "performance_management_home",
    path: "/app/performance_management/home",
    component: PerformanceManagementHomeView,
  },
]);

const getNavItems = () => ([
  {
    id: "home",
    title: "Home",
    isEnabled: true,
    alwaysVisible: true, // this will make Home item always visible regardless of url / module.
    children: [
      { id: "dashboard", title: "My Dashboard", path: "/app/home/dashboard", icon: "DashboardOutlined", component: DashboardView, accessKey: "dashboard.view", },
      { id: "personal", title: "Personal", icon: "PersonOutlineOutlined", children: [
        {
          id: "inbox", title: "Inbox", icon: "EmailOutlined", component: InboxView, accessKey: "always_show",
          path: [
            "/app/home/personal/inbox",
            "/app/home/personal:id",
          ],
        },
    ]}
    ]
  },
  {
    id: "organization",
    title: "Organization",
    altTitle: "Organization Management",
    isEnabled: true,
    description: "Manages organization structure and roles",
    landingPath: "/app/organization/home",
    rootPath: "/app/organization",
    children: [
      { id: "dummy", title: "Dummies", icon: "AccountBoxOutlined", path: ["/app/organization/dummies", "/app/organization/dummies/filter/:params?", "/app/organization/dummies/new", "/app/organization/dummies/:id", "/app/organization/dummies/edit/:id"], component: DummyView},
      { id: "supervisory", title: "Governance", icon: "Business", path: ["/app/organization/governances", "/app/organization/governances/:type", "/app/organization/governances/:type/filter/:params?", "/app/organization/governances/:type/new/:tab", "/app/organization/governances/:type/:id", "/app/organization/governances/:type/edit/:id/:tab", "/app/organization/governances/:type/:id/:tab", "/app/organization/supervisories", "/app/organization/supervisories/:type", "/app/organization/supervisories/:type/filter/:params?", "/app/organization/supervisories/:type/new/:tab", "/app/organization/supervisories/:type/:id", "/app/organization/supervisories/:type/edit/:id/:tab", "/app/organization/supervisories/:type/:id/:tab"], component: SupervisoryView, accessKey: "supervisory.view", },
      { id: "company", title: "Companies", icon: "LocationCityOutlined", path: ["/app/organization/companies", "/app/organization/companies/filter/:params?", "/app/organization/companies/new", "/app/organization/companies/:id"], component: CompanyView, accessKey: "companies.view", },
      { id: "department", title: "Departments", icon: "AccountBalanceOutlined", path: ["/app/organization/departments", "/app/organization/departments/filter/:params?", "/app/organization/departments/new", "/app/organization/departments/:id", "/app/organization/departments/edit/:id"], component: DepartmentView, accessKey: "departments.view", },
      { id: "position", title: "Positions", icon: "WorkOutlineOutlined", path: ["/app/organization/positions", "/app/organization/positions/:company_id", "/app/organization/positions/:company_id/filter/:params?", "/app/organization/position/new", "/app/organization/position/:id"], component: PositionView, accessKey: "positions.view", },
      { id: "locations", title: "Locations", icon: "LocationOnOutlined", path: ["/app/organization/locations", "/app/organization/locations/filter/:params?", "/app/organization/locations/new", "/app/organization/locations/:id"], component: LocationView, accessKey: "locations.view", },
      { id: "organization_role", title: "Organization Roles", icon: "ManageAccountsOutlined", path: ["/app/organization/organization_roles", "/app/organization/organization_roles/filter/:params?", "/app/organization/organization_roles/new", "/app/organization/organization_roles/:id"], component: OrganizationRoleView, accessKey: "organization_roles.view", },
    ]
  },
  {
    id: "workflow",
    title: "Workflows",
    altTitle: "Workflow Management",
    isEnabled: true,
    description: "Automates processes and approvals",
    landingPath: "/app/workflows/home",
    rootPath: "/app/workflows",
    children: [
      {
        id: "workflow_request_to_hire", title: "Request to Hire", icon: "AccountTreeOutlined", component: RequestToHireWorkFlowView, accessKey: "request_to_hire.view",
        path: [
          "/app/workflows/request_to_hire",
          "/app/workflows/request_to_hire/new",
          "/app/workflows/request_to_hire/:id",
        ],
      },
    ]
  },
  {
    id: "recruitment",
    title: "Recruitment",
    altTitle: "Recruitment",
    isEnabled: true,
    description: "Hiring process and track candidates",
    landingPath: "/app/recruitment/home",
    rootPath: "/app/recruitment",
    children: [
      { id: "dashboard", title: "Dashboard", altTitle: "Recruitment Dashboard", path: "/app/recruitment/recruitment_dashboard", icon: "DashboardOutlined", component: RecruitmentDashboardView, accessKey: "recruitment.dashboard", },
      { id: "job_template", title: "Job Templates", icon: "ArticleOutlined", path: ["/app/recruitment/job_templates", "/app/recruitment/job_templates/filter/:params?", "/app/recruitment/job_templates/new", "/app/recruitment/job_templates/:id", "/app/recruitment/job_templates/edit/:id"], component: JobTemplateView, accessKey: "job_templates.view" },
      {
        id: "manpower_requests", title: "Manpower Requests", icon: "AssignmentIndOutlined", component: ManPowerRequestView, accessKey: "manpower_requests.view",
        path: [
          "/app/recruitment/manpower_requests",
          "/app/recruitment/manpower_requests/my_tasks",
          "/app/recruitment/manpower_requests/new",
          "/app/recruitment/manpower_requests/:id",
          "/app/recruitment/manpower_requests/edit/:id",
          "/app/recruitment/manpower_requests/:params?",
        ]
      },
      {
        id: "job_posting", title: "Job Postings", icon: "PostAddOutlined", component: JobPostingView, accessKey: "job_postings.view",
        path: [
          "/app/recruitment/job_postings",
          "/app/recruitment/job_postings/filter/:params?",
          "/app/recruitment/job_postings/new",
          "/app/recruitment/job_postings/:id",
          "/app/recruitment/job_postings/edit/:id",
          "/app/recruitment/manage_applications/:job_posting_id/:stage_id",
          "/app/recruitment/manage_applications/:job_posting_id/:stage_id/filter/:params?",
        ]
      },
      { id: "applicant", title: "Applicants", icon: "AccountBoxOutlined", path: ["/app/recruitment/applicants", "/app/recruitment/applicants/:page_no", "/app/recruitment/applicants/:page_no/filter/:params?"], component: ApplicantView, accessKey: "applicants.view", },
      {
        id: "stages", title: "Stages", icon: "FactCheckOutlined", component: RecruitmentStagesView, accessKey: "stages.view",
        path: [
          "/app/recruitment/stages",
          "/app/recruitment/stages/new",
          "/app/recruitment/stages/filter/:params?",
          "/app/recruitment/stages/:id",
          "/app/recruitment/stages/edit/:id",
        ],
      },
      {
        id: "settings", title: "Settings", icon: "SettingsOutlined", children: [
          {
            id: "job_applications", title: "Job Applications", component: JobApplicationSettingView, accessKey: "recruitment_settings.job_applications.view",
            path: [
              "/app/recruitment/settings/job_applications",
              "/app/recruitment/settings/job_applications/new",
              "/app/recruitment/settings/job_applications/:id",
              "/app/recruitment/settings/job_applications/edit/:id",
            ],
          },
          { id: "pages", title: "Pages", path: "/app/recruitment/settings/pages", component: RecruitmentPagesView, accessKey: "recruitment_pages.view", },
          { id: "data_migrations", title: "Data Migrations", path: "/app/recruitment/settings/data_migrations", component: DataMigrationView, accessKey: "recruitment_settings.data_migrations.view", },
        ]
      },
    ]
  },
  {
    id: "hr",
    title: "HR",
    altTitle: "HR Management",
    isEnabled: true,
    description: "Centralize employee records and streamline HR processes",
    landingPath: "/app/hr/home",
    rootPath: "/app/hr",
    children: [
      {
        id: "employees", title: "Employees", icon: "PeopleAltOutlined", component: EmployeeView, accessKey: "employees.view",
        path: [
          "/app/hr/employees",
          "/app/hr/employees/:params?"
        ],
      },
      {
        id: "workflow_personnel_action_notice", title: "PAN Workflow", icon: "ApprovalOutlined", component: PersonnelActionNoticeView, accessKey: "personnel_action_notice.view",
        path: [
          "/app/hr/personnel_action_notice",
          "/app/hr/personnel_action_notice/new",
          "/app/hr/personnel_action_notice/:id",
        ],
      },
      { 
        id: "pan_types", title: "PAN Types", icon: "FilterListOutlined", 
        component: PanTypeView, accessKey: "data_management.view", 
        path: [
          "/app/hr/pan_types", 
          "/app/hr/pan_types/new", 
          "/app/hr/pan_types/:id",
          "/app/hr/pan_types/edit/:id",
          "/app/hr/pan_types/filter/:params?"
        ],
      },
      {
        id: "pan_requests", title: "PAN Requests", icon: "ModeEditOutlined", component: PanRequestView, accessKey: "pan_requests.view",
        path: [
          "/app/hr/pan_requests",
          "/app/hr/pan_requests/my_tasks",
          "/app/hr/pan_requests/new",
          "/app/hr/pan_requests/:id",
          "/app/hr/pan_requests/edit/:id",
          "/app/hr/pan_requests/:params?",
        ]
      },
    ]
  },
  {
    id: "reports",
    title: "Reports",
    altTitle: "Reports",
    isEnabled: true,
    description: "Contains all reports for data analysis and insights",
    landingPath: "/app/reports/home",
    rootPath: "/app/reports",
    children: [
      {
        id: "activity_logs", title: "Activity Logs", icon: "TimelineOutlined", component: ActivityLogsReportView, accessKey: "activity_logs.view",
        path: [
          "/app/reports/activity_logs",
          "/app/reports/activity_logs/companies",
          "/app/reports/activity_logs/companies/:page_no/filter/:params?",
          "/app/reports/activity_logs/users",
          "/app/reports/activity_logs/users/:page_no/filter/:params?",
          "/app/reports/activity_logs/locations",
          "/app/reports/activity_logs/locations/:page_no/filter/:params?",
          "/app/reports/activity_logs/positions",
          "/app/reports/activity_logs/positions/:page_no/filter/:params?",
          "/app/reports/activity_logs/job_postings",
          "/app/reports/activity_logs/job_postings/:page_no/filter/:params?",
          "/app/reports/activity_logs/job_templates",
          "/app/reports/activity_logs/job_templates/:page_no/filter/:params?",
          "/app/reports/activity_logs/departments",
          "/app/reports/activity_logs/departments/:page_no/filter/:params?",
          "/app/reports/activity_logs/request_to_hire_workflows",
          "/app/reports/activity_logs/request_to_hire_workflows/:page_no/filter/:params?",
          "/app/reports/activity_logs/roles",
          "/app/reports/activity_logs/roles/:page_no/filter/:params?",
          "/app/reports/activity_logs/sup_orgs",
          "/app/reports/activity_logs/sup_orgs/:page_no/filter/:params?",
          "/app/reports/activity_logs/applicant_movements",
          "/app/reports/activity_logs/applicant_movements/:page_no/filter/:params?",
          // "/app/reports/activity_logs/dummies",
        ]
      },
      {
        id: "recruitment_reports", title: "Recruitment", icon: "PersonSearchOutlined", component: RecruitmentReportView, accessKey: "recruitments.view",
        path: [
          "/app/reports/recruitments",
          "/app/reports/recruitments/job_postings",
          "/app/reports/recruitments/job_postings/:page_no/filter/:params?",
          "/app/reports/recruitments/applicant_printouts",
          "/app/reports/recruitments/vacancy_filled_breakdowns",
          "/app/reports/recruitments/vacancy_filled_breakdowns/:page_no/filter/:params?",
        ]
      },
      {
        id: "lms_report", title: "LMS", icon: "AutoStoriesOutlined", component: LMSReportView, accessKey: "learning_management.view",
        path: [
          "/app/reports/learning_management",
          "/app/reports/learning_management/summary",
          "/app/reports/learning_management/summary/:page_no/filter/:params?",
          "/app/reports/learning_management/detailed",
          "/app/reports/learning_management/detailed/:page_no/filter/:params?",
          "/app/reports/learning_management/course_leaner_count",
          "/app/reports/learning_management/course_leaner_count/:page_no/filter/:params?",
          "/app/reports/learning_management/course_quiz_report",
          "/app/reports/learning_management/course_quiz_report/:page_no/filter/:params?",
          "/app/reports/learning_management/instructor",
          "/app/reports/learning_management/instructor/:page_no/filter/:params?",
          "/app/reports/learning_management/quiz_performance",
          "/app/reports/learning_management/quiz_performance/:page_no/filter/:params?",
        ] 
      },
    ]
  },
  {
    id: "user_management",
    title: "User Management",
    altTitle: "User Management",
    isEnabled: true,
    description: "Manages user accounts, system roles and permissions",
    landingPath: "/app/user_management/home",
    rootPath: "/app/user_management",
    children: [
      { id: "users", title: "External Users", icon: "AccountCircleOutlined", path: ["/app/user_management/external_users", "/app/user_management/external_users/filter/:params?", "/app/user_management/external_users/new", "/app/user_management/external_users/:id"], component: UserView, accessKey: "users.view", },
      { id: "roles", title: "System Roles", icon: "SupervisedUserCircleOutlined", path: ["/app/user_management/system_roles", "/app/user_management/system_roles/filter/:params?", "/app/user_management/system_roles/new", "/app/user_management/system_roles/:id"], component: RoleView, accessKey: "roles.view", },
    ]
  },
  {
    id: "data_management",
    title: "Data Management",
    altTitle: "Data Management",
    isEnabled: true,
    description: "Manages user data",
    landingPath: "/app/data_management/home",
    rootPath: "/app/data_management",
    children: [
    ]
  },
  {
    id: "learning_management",
    title: "Learning Management",
    altTitle: "Learning Management",
    description: "The essential training tool for businesses that want to build a competitive and skilled workforce",
    landingPath: "/app/learning_management/home",
    rootPath: "/app/learning_management",
    isEnabled: false,
    children: [
      {
        id: "my_courses", title: "My Courses", icon: "AutoStoriesOutlined", component: CourseLearnersView, accessKey: "learning_management.my_courses",
        path: [
          "/app/learning_management/my_courses",
          "/app/learning_management/my_courses/:id",
          "/app/learning_management/my_courses/on_going",
          "/app/learning_management/my_courses/on_going/:id",
          "/app/learning_management/my_courses/completed",
          "/app/learning_management/my_courses/completed/:id",
          "/app/learning_management/my_courses/learnings/:id",
          "/app/learning_management/my_courses/on_going/learnings/:id",
          "/app/learning_management/my_courses/completed/learnings/:id",
        ]
      },
      { 
        id: "courses", title: "Courses", icon: "LocalLibraryOutlined", component: CourseView, accessKey: "learning_management.view", 
        path: [
          "/app/learning_management/courses", 
          "/app/learning_management/courses/filter/:params?", 
          "/app/learning_management/courses/new", 
          "/app/learning_management/courses/:id",
          "/app/learning_management/courses/new/instructions",
          "/app/learning_management/courses/new/general_info",
          "/app/learning_management/courses/new/target_learners",
          "/app/learning_management/courses/new/content",
          "/app/learning_management/courses/new/summary_&_review",
          "/app/learning_management/courses/new/archives",
          "/app/learning_management/courses/:id/general_info",
          "/app/learning_management/courses/:id/instructions",
          "/app/learning_management/courses/:id/content",
          "/app/learning_management/courses/:id/content/:file_name",
          "/app/learning_management/courses/:id/target_learners",
          "/app/learning_management/courses/:id/summary_&_review",
          "/app/learning_management/courses/:id/archives",
        ], 
      },
    ]
  },
  {
    id: "performance_management",
    title: "Performance Management",
    altTitle: "Performance Management",
    description: "Boost performance & development. Streamline reviews & feedback. Performance management made easy",
    landingPath: "/app/performance_management/home",
    rootPath: "/app/performance_management",
    isEnabled: false,
    children: [
      {
        id: "scoring_template", title: "Scoring Templates", icon: "GradingOutlined", component: ScoringTemplateView, accessKey: "performance_management.scoring_template",
        path: [
          "/app/performance_management/scoring_template",
          "/app/performance_management/scoring_template/filter/:params?", 
          "/app/performance_management/scoring_template/new", 
          "/app/performance_management/scoring_template/:id",
          "/app/performance_management/scoring_template/edit/:id",
        ]
      },
      {
        id: "evaluation_template", title: "Evaluation Templates", icon: "AssessmentOutlined", component: EvaluationTemplateView, accessKey: "performance_management.evaluation_template",
        path: [
          "/app/performance_management/evaluation_template",
          "/app/performance_management/evaluation_template/filter/:params?",
          "/app/performance_management/evaluation_template/new", 
          "/app/performance_management/evaluation_template/:id",
          "/app/performance_management/evaluation_template/edit/:id",
        ]
      },
      {
        id: "pms_workflow", title: "Workflow", icon: "AccountTreeOutlined", component: PmsWorkflowView, accessKey: "performance_management.workflow",
        path: [
          "/app/performance_management/pms_workflow",
          "/app/performance_management/pms_workflow/new", 
          "/app/performance_management/pms_workflow/filter/:params?",
          "/app/performance_management/pms_workflow/:id",
        ]
      },
      {
        id: "pms_evaluation_request", title: "Evaluation Request", icon: "RateReviewOutlined", component: EvaluationRequestView, accessKey: "performance_management.evaluation_request",
        path: [
          "/app/performance_management/evaluation_request",
          "/app/performance_management/evaluation_request/new", 
          "/app/performance_management/evaluation_request/:id",
          "/app/performance_management/evaluation_request/edit/:id",
          "/app/performance_management/evaluation_request/:params?",
        ]
      },
      {
        id: "pms_approved_evaluations", title: "Approved Evaluations", icon: "TaskOutlined", component: OngoingEvaluations, accessKey: "performance_management.approved_evaluations",
        path: [
          "/app/performance_management/approved_evaluations",
          "/app/performance_management/approved_evaluations/new", 
          "/app/performance_management/approved_evaluations/edit/:eval_request_id/:employee_id",
          "/app/performance_management/approved_evaluations/:params?",
          "/app/performance_management/approved_evaluations/:employee_id",
          "/app/performance_management/approved_evaluations/filter/:params?",
        ]
      },
    ]
  },
]);

export {
  getNavItems,
  getNonNavItems,
}
